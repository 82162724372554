// import classNames from "classnames";
import { useInvestment } from "../../hooks/loan/useInvestment";
import InputForm from "../form/InputForm";
import Input from "../input/input";
// import { useState } from "react";
import arrow from "../../assets/arrow-left.svg";
import { addThousandSeparator } from "../../utils";
import { useState } from "react";
import "./header.css";
// import Success from "./Success";
import bg from '../../assets/invest.jpg'

export const Investment = ({ handleClose }) => {
  const {
    handleSubmit,
    investStatus,
    inputFile,
    amount,
    handleChange3,
    investType,
    handleChange,
    setInvestType,
    setInvestMenu,
    setInvestStatus,
    investTypeList,
    loading,
    investListMenu,
    investStatus2,
    investMenu,
    setInvestStatus2,
    setInvestFreq,
    investFreq,
    setFreqStatus,
    investFreqMenu,
    freqStatus,
    amount2,
  } = useInvestment();
  const [minDate, setMinDate] = useState("");
  // const [view, setView] = useState(0);

  // const changeCategory = (name) => {
  //   setCategory(name);
  // };
  // const handleForm = () => {
  //   setView(1);
  // };
  const calculateMinDate = () => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    const formattedMinDate = minDate.toISOString().split("T")[0]; // Format the date as yyyy-mm-dd
    setMinDate(formattedMinDate);
  };
  return (
    <div className="flex h-screen w-full justify-between overflow-hidden">
      <div className="investment w-1/2">
        <img src={bg} className="w-full object-cover" alt="" />
      </div>
      <div className="mx-auto flex w-full flex-col space-y-4 overflow-y-scroll p-5 sm:w-3/4 lg:relative lg:mx-0 lg:ml-auto lg:w-1/2">
        <div className="w-3/4 mx-auto">
          <h2 className=" my-5 text-center text-xl font-semibold xl:text-3xl">
            Have a better future with our <br /> Investment Products 🚀
          </h2>
          <Input
            label="Investment Category"
            dropdown={true}
            action={(e) => {
              setInvestType(e.target.outerText);
              setInvestStatus(!investStatus);
            }}
            product={investType}
            dropdownAction={() => {
              setInvestStatus(!investStatus);
            }}
            dropdownList={investTypeList}
            productStatus={investStatus}
          />
          {investType === "Fast Credit Investment" && (
            <Input
              label="Type of Investment"
              dropdown={true}
              action={(e) => {
                setInvestMenu(e.target.outerText);
                setInvestStatus2(!investStatus2);
              }}
              product={investMenu}
              dropdownAction={() => {
                setInvestStatus2(!investStatus2);
              }}
              dropdownList={investListMenu}
              productStatus={investStatus2}
            />
          )}
          <InputForm
            value={inputFile.desc}
            type="text"
            label="Description:"
            handleChange={handleChange}
            name="desc"
            placeholder="rent, vacation etc..."
          />
          <div className="input__form">
            <label htmlFor="Amount" className="label">
              {investType === "Target Savings" ? "Target Amount" : "Amount"}
            </label>
            <input
              style={{
                width: "auto",
                borderBottom: amount ? "2px solid #029247" : "none",
                background: amount ? "#F2FCF5" : "#F6F6F6",
              }}
              className="input"
              value={amount}
              type="text"
              onChange={handleChange3}
              name="amount"
              placeholder="9,999"
            />
          </div>
          {investType === "Target Savings" && (
            <>
              <Input
                label="Saving Frequency"
                dropdown={true}
                action={(e) => {
                  setInvestFreq(e.target.outerText);
                  setFreqStatus(!freqStatus);
                }}
                product={investFreq}
                dropdownAction={() => {
                  setFreqStatus(!freqStatus);
                }}
                dropdownList={investFreqMenu}
                productStatus={freqStatus}
              />
            </>
          )}
          {/* {investType === "Fast Credit Investment" && (
        <div className="input__form cursor-pointer">
          <label htmlFor="tenure" className="label">
            Investment Tenure:
          </label>
          <div className="flex items-center justify-around">
            {["90", "180", "365"].map((item) => (
              <div
                key={item}
                onClick={() => changeCategory(item)}
                className={classNames(
                  "my-4 flex w-[98px] flex-col items-center  justify-center rounded-xl shadow",
                  {
                    "shadow-xl": category === item,
                  }
                )}
              >
                <span
                  className={classNames("text-2xl font-bold", {
                    "w-full rounded-t-xl bg-[#01612F] py-2 text-center text-white":
                      category === item,
                  })}
                >
                  {item}
                </span>
                <h2
                  className={classNames(
                    "w-full rounded-b-xl bg-[#D0F6DF] p-2 text-center  text-sm   text-[#029247]",
                    {
                      "font-semibold text-[#01612F]": category === item,
                    }
                  )}
                >
                  Days
                </h2>
              </div>
            ))}
          </div>
        </div>
      )} */}
          <>
            {/* <InputForm
            value={inputFile.startDate}
            type="date"
            handleChange={handleChange}
            label="start date:"
            name="startDate"
            placeholder="0816389999"
          />
          <InputForm
            value={inputFile.endDate}
            type="date"
            handleChange={handleChange}
            label="end date:"
            name="endDate"
            placeholder="0816389999"
          /> */}
            <div className="input__form">
              <label htmlFor="startDate" className="label ">
                start Date:
              </label>
              <input
                type="date"
                style={{
                  width: inputFile.startDate === "fullname" ? "auto" : "auto",
                  borderBottom: inputFile.startDate
                    ? "2px solid #029247"
                    : "none",
                  background: inputFile.startDate ? "#F2FCF5" : "#F6F6F6",
                }}
                onChange={handleChange}
                name="startDate"
                className="input rounded"
                value={inputFile.startDate}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="input__form">
              <label htmlFor="endDate" className="label ">
                end Date:
              </label>
              <input
                type="date"
                style={{
                  width: inputFile.endDate === "fullname" ? "auto" : "auto",
                  borderBottom: inputFile.endDate
                    ? "2px solid #029247"
                    : "none",
                  background: inputFile.endDate ? "#F2FCF5" : "#F6F6F6",
                }}
                onChange={handleChange}
                name="endDate"
                className="input rounded"
                value={inputFile.endDate}
                min={
                  inputFile?.startDate &&
                  new Date(inputFile.startDate)?.toISOString()?.split("T")[0]
                }
              />
            </div>
          </>
          {investType === "Target Savings" && amount2 && (
            <>
              <div className="input__form">
                <label htmlFor="Amount" className="label">
                  Frequency Amount
                </label>
                <input
                  style={{
                    width: "auto",
                    borderBottom: amount2 ? "2px solid #029247" : "none",
                    background: amount2 ? "#F2FCF5" : "#F6F6F6",
                  }}
                  className="input"
                  value={addThousandSeparator(Number(amount2)?.toFixed(2))}
                  type="text"
                  // onChange={handleChange4}
                  name="amount"
                  placeholder="9,999"
                />
              </div>
            </>
          )}
          <InputForm
            value={inputFile.name}
            type="text"
            label="Name:"
            handleChange={handleChange}
            name="name"
            placeholder="john doe"
          />
          <InputForm
            value={inputFile.email}
            type="email"
            label="Email:"
            handleChange={handleChange}
            name="email"
            placeholder="johdoe@yahoo.com"
          />

          <InputForm
            value={inputFile.phone}
            type="number"
            handleChange={handleChange}
            label="Phone Number:"
            name="phone"
            placeholder="0816389999"
            pattern="[0-9]*"
            onInput={(e) => (e.target.value = e.target.value.slice(0, 11))}
          />

          <InputForm
            value={inputFile.bvn}
            type="number"
            handleChange={handleChange}
            label="BVN:"
            name="bvn"
            placeholder="21816389999"
            pattern="[0-9]*"
            onInput={(e) => (e.target.value = e.target.value.slice(0, 11))}
          />

          <InputForm
            value={inputFile.nin}
            type="number"
            handleChange={handleChange}
            label="NIN Number:"
            name="nin"
            placeholder="22816389999"
            pattern="[0-9]*"
            onInput={(e) => (e.target.value = e.target.value.slice(0, 11))}
          />

          {/* <InputForm
        value={inputFile.dob}
        type="date"
        handleChange={handleChange}
        label="Date of birth:"
        name="dob"
        placeholder="0816389999"
        max={new Date().toISOString().split("T")[0]}
      /> */}
          <div className="input__form">
            <label htmlFor="dob" className="label ">
              Date of birth:
            </label>
            <input
              type="date"
              style={{
                width: inputFile.dob === "fullname" ? "auto" : "auto",
                borderBottom: inputFile.dob ? "2px solid #029247" : "none",
                background: inputFile.dob ? "#F2FCF5" : "#F6F6F6",
              }}
              onChange={handleChange}
              name="dob"
              className="input rounded"
              value={inputFile.dob}
              max={minDate}
              onFocus={calculateMinDate}
            />
          </div>

          <button
            style={{
              background: "#029247",
            }}
            onClick={handleSubmit}
            className="submit_loan p-3"
          >
            {loading ? "processing..." : "Invest Now"}
          </button>

          {/* {view === 1 && (
        <InvestSummary
          summaryData={""}
          amount={amount}
          loading={false}
          setView={setView}
          product={category}
          num={0}
          submit={submit}
        />
      )} */}
          {/* {view === 2 && <Success handleClose={handleClose} />} */}
        </div>
      </div>
    </div>
  );
};

export const InvestSummary = ({
  num,
  loading,
  setView,
  submit,
  summaryData,
  product,
  amount,
}) => {
  const Loantenor =
    product === "365"
      ? 12
      : product === "90"
      ? 3
      : product === "180"
      ? 6
      : product.toString().toLowerCase().replace("months", "");
  const totalRepayment = summaryData?.monthlyRepayment * Loantenor;

  return (
    <>
      <img
        src={arrow}
        onClick={() => setView(num)}
        className="w-7 cursor-pointer"
        alt="back"
      />
      {loading ? (
        <div className="flex items-center justify-center">
          <img
            className="h-16 w-16"
            src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
            alt=""
          />
        </div>
      ) : !summaryData?.interest ? (
        <>
          <div className="flex flex-col items-center justify-center">
            <h3 className="text-3xl font-semibold">Investment Summary</h3>
            <p className=" text-center text-xs text-[#626262] sm:text-sm">
              Summary of your investment details below and confirm.
            </p>
          </div>
          <section className="flex flex-col space-y-5">
            <div className="flex items-center justify-between">
              <h3> Interest Amount</h3>
              <p className="font-semibold ">
                &#8358;
                {amount && addThousandSeparator(amount)}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h3>Interest Income</h3>
              <p className="font-semibold ">
                {product}{" "}
                {product.toLowerCase().includes("months") ? null : "days"}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h3>Interest Rate </h3>
              <p className="font-semibold ">{summaryData?.interest} %</p>
            </div>
            <div className="flex items-center justify-between">
              <h3>Maturity Amount</h3>
              <p className="font-semibold ">
                &#8358;
                {summaryData?.monthlyRepayment &&
                  addThousandSeparator(summaryData?.monthlyRepayment)}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h3>Principal Amount</h3>
              <p className="font-semibold ">
                &#8358;
                {totalRepayment &&
                  addThousandSeparator(totalRepayment.toFixed(2))}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h3>Investment Tenure</h3>
              <p className="font-semibold ">{product} days</p>
            </div>
            <div className="flex items-center justify-center">
              <button
                onClick={(e) => submit(e)}
                className="mt-5 w-[350px] rounded-full bg-[#029247] px-6 py-3 font-semibold text-white"
              >
                Confirm
              </button>
            </div>
          </section>
        </>
      ) : (
        <div className="mt-5  flex flex-col space-y-4 font-semibold">
          <p className="text-xl font-bold text-red-500">Possible error: 😞</p>
          <p className="capitalize"> {">> "}Low Amount must be 100K above </p>
          <p className="capitalize">
            {">> "}Loan Amount Exceeded, Please Reduce Loan Amount Or Increase
            Tenor
          </p>
          <p className="capitalize"> {">> "}Low Tenor </p>
          <p className="capitalize"> {">> "}poor internet connection.</p>
        </div>
      )}
    </>
  );
};
