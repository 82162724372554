import { CloseIcon } from "./CloseIcon";

const CustomModal = ({ children, handleClose }) => {
  return (
    <>
      <div className="fixed inset-0 z-[5101] flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-50 outline-none backdrop-blur focus:outline-none">
        <div className="relative mx-auto my-6 w-auto max-w-3xl">
          <div className=" relative flex w-[400px] flex-col rounded-xl border-none bg-white py-6 shadow-lg outline-none focus:outline-none sm:w-[500px]">
            <p onClick={handleClose} className="close !mr-0 !mt-2 sticky">
              <CloseIcon />
            </p>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomModal;
